import React, { useState } from "react";
import "./App.css";
// import Logo from "./logo.png";
import { useNavigate } from "react-router-dom";

function Auth() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form from submitting the traditional way
    setLoading(true);
    setError("");

    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to login");
      }

      if (data) {
        // let d2 = JSON.parse(data);
        // console.log("d2", d2);
        sessionStorage.setItem("d", data.accessToken);
        navigate("/admin");
      }

      // Assuming `data` contains some user info or success message
      // Redirect user or show success message
      // For example, you could save the user info to state, local storage, or context
      // and redirect the user to another page
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleLogin}>
        {/* <img src={Logo} alt="Project" /> */}
        <h2>Login</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <input
          type="text"
          placeholder="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
      </form>
    </div>
  );
}

export default Auth;
