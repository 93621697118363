import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Edit() {
  let [thumbnail, setThumbnail] = useState(null);
  const [title, setTitle] = useState(""); // Assuming you have a title state
  const [htmlDescription, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const { id: projectId } = useParams();

  useEffect(() => {
    const token = sessionStorage.getItem("d");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    // Check if file is selected
    if (!file) {
      alert("Please select a file.");
      return;
    }

    // Check for image MIME type
    if (!file.type.match("image.*")) {
      alert("Please select an image file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/api/upload", {
        method: "POST",
        headers: {
          Authorization: sessionStorage.getItem("d"),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      setThumbnail(response.filename); // Assuming you have a thumbnail state

      const result = await response.json();
      alert("Image uploaded successfully");
      console.log("Uploaded Image Details:", result);
      // Handle further actions here, like updating UI or state with the image details
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Example of collecting form data
    const projectData = {
      title: title, // Assuming you have title in your component's state
      htmlDescription: htmlDescription, // Assuming you have description in your component's state
      thumbnail: thumbnail, // Assuming you have thumbnail in your component's state
    };

    // Optional: Validate your form data here

    try {
      const response = await fetch(`/api/projects/${projectId}`, {
        // Adjust the URL as needed
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("d"),
        },
        body: JSON.stringify(projectData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new project");
      }

      const result = await response.json();
      console.log("Project updated successfully:", result);
      // Handle further actions here, like clearing the form or updating the UI
      alert("Project updated successfully");
      // window.location.reload();
      navigate("/");
    } catch (error) {
      console.error("Error updating project:", error);
      alert("Error updating project");
    }
  };

  useEffect(() => {
    loadProject(projectId);
  }, []);

  const loadProject = async (id) => {
    try {
      const response = await fetch(`/api/projects/${id}`, {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("d"), // Assuming you need authorization
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the project");
      }

      const project = await response.json();
      // Update your component's state with the fetched data
      setTitle(project.title);
      setDescription(project.htmlDescription);
      // Assuming you have a way to set the thumbnail from the project data
      setThumbnail(project.thumbnail);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <h1>
        {" "}
        <a href="/">Go back</a>{" "}
      </h1>
      <form onSubmit={handleSubmit}>
        <input
          disabled
          readOnly
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {/* <br />
        <br />
        <input type="file" onChange={handleImageChange} disabled={uploading} />
        {uploading && <p>Uploading...</p>} */}
        <br />
        <br />
        <ReactQuill
          theme="snow"
          value={htmlDescription}
          onChange={setDescription}
        />
        <br />
        <br />
        <button type="submit">Update project</button>
      </form>
    </div>
  );
}

export default Edit;
