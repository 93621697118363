import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Admin from "./Admin";
import Edit from "./Edit";
import Login from "./Auth";
import ViewProject from "./ViewProject";

function App() {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/project/:id" element={<ViewProject />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
