import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  function addProject() {
    navigate("/login");
  }
  function goHome() {
    navigate("/");
  }

  return (
    <nav>
      <div>
        <img
          onClick={() => goHome()}
          // src={Logo}
          style={{ width: 100 }}
          alt="Project Logo"
        />
      </div>
      {/* <button>Python</button>
      <button>Dot Net</button>
      <button>PHP</button> */}

      {sessionStorage.getItem("d") && (
        <>
          <button
            onClick={() => addProject()}
            style={{
              backgroundColor: "green",
              color: "white",
              marginRight: 10,
            }}
          >
            Add project
          </button>
        </>
      )}

      {sessionStorage.getItem("d") && (
        <>
          <button
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
            style={{ backgroundColor: "red", color: "white", marginRight: 10 }}
          >
            Logout
          </button>
        </>
      )}
    </nav>
  );
}

function ViewProject() {
  const [fullProject, setFullProject] = useState("");
  const navigate = useNavigate();
  const { id: projectId } = useParams();

  useEffect(() => {
    loadProject(projectId);
  }, []);

  const loadProject = async (id) => {
    try {
      const response = await fetch(`/api/projects/${id}`, {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("d"), // Assuming you need authorization
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the project");
      }

      const project = await response.json();
      // Update your component's state with the fetched data
      setFullProject(project);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="card" style={{ padding: 10, margin: 10 }}>
        <h3>{fullProject.title}</h3>
        <img
          src={"/api/image/" + fullProject.thumbnail}
          alt={fullProject.title}
        />

        <div
          dangerouslySetInnerHTML={{ __html: fullProject.htmlDescription }}
        ></div>
      </div>
    </div>
  );
}

export default ViewProject;
