import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

function Admin() {
  let [thumbnail, setThumbnail] = useState(null);
  const [title, setTitle] = useState(""); // Assuming you have a title state
  const [htmlDescription, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("d");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    // Check if file is selected
    if (!file) {
      alert("Please select a file.");
      return;
    }

    // Check for image MIME type
    if (!file.type.match("image.*")) {
      alert("Please select an image file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/api/upload", {
        method: "POST",
        headers: {
          Authorization: sessionStorage.getItem("d"),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const result = await response.json();
      alert("Image uploaded successfully");
      // console.log("Uploaded Image Details:", result);

      // console.log(result.filename);
      setThumbnail(result.filename);
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Example of collecting form data
    const projectData = {
      title: title, // Assuming you have title in your component's state
      htmlDescription: htmlDescription, // Assuming you have description in your component's state
      thumbnail: thumbnail, // Assuming you have thumbnail in your component's state
    };

    // Optional: Validate your form data here

    try {
      const response = await fetch("/api/projects", {
        // Adjust the URL as needed
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("d"),
        },
        body: JSON.stringify(projectData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new project");
      }

      const result = await response.json();
      // console.log("Project added successfully:", result);
      // Handle further actions here, like clearing the form or updating the UI
      alert("Project added successfully");
      // window.location.reload();
      navigate("/");
    } catch (error) {
      console.error("Error adding project:", error);
      alert("Error adding project");
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <h1>
        {" "}
        <a href="/">Go back</a>{" "}
      </h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <input type="file" onChange={handleImageChange} disabled={uploading} />
        {uploading && <p>Uploading...</p>}
        <br />
        <br />
        <ReactQuill
          theme="snow"
          value={htmlDescription}
          onChange={setDescription}
        />
        <br />
        <br />
        <button type="submit">Add project</button>
      </form>
    </div>
  );
}

export default Admin;
