import React, { useState, useEffect, useRef } from "react";
import "./App.css";
// import Logo from "./logo.png";
import "./ImageSlider.css"; // Make sure to create this CSS file for styling
import Axios from "axios";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  function addProject() {
    navigate("/login");
  }

  return (
    <nav>
      <div>
        <img
          // src={Logo}
          style={{ width: 100 }}
          alt="Project Logo"
        />
      </div>
      {/* <button>Python</button>
      <button>Dot Net</button>
      <button>PHP</button> */}

      {sessionStorage.getItem("d") && (
        <>
          <button
            onClick={() => addProject()}
            style={{
              backgroundColor: "green",
              color: "white",
              marginRight: 10,
            }}
          >
            Add project
          </button>
        </>
      )}

      {sessionStorage.getItem("d") && (
        <>
          <button
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
            style={{ backgroundColor: "red", color: "white", marginRight: 10 }}
          >
            Logout
          </button>
        </>
      )}
    </nav>
  );
}

const ImageSlider = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const length = images.length;
  const touchStartXRef = useRef();

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (!touchStartXRef.current) {
      return;
    }

    const touchEndX = e.changedTouches[0].clientX;
    const direction = touchEndX - touchStartXRef.current;

    // Threshold could be adjusted according to your needs
    if (direction > 50) {
      // Right swipe
      prevSlide();
    } else if (direction < -50) {
      // Left swipe
      nextSlide();
    }

    touchStartXRef.current = null; // Reset touch start position
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }

  return (
    <section
      className="slider"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <button className="left-arrow" onClick={prevSlide}>
        &lt;
      </button>
      <button className="right-arrow" onClick={nextSlide}>
        &gt;
      </button>
      {images.map((image, index) => (
        <div
          className={index === current ? "slide active" : "slide"}
          key={index}
        >
          {index === current && (
            <img
              key={index}
              src={image}
              alt={"Slide " + index}
              className="image"
            />
          )}
        </div>
      ))}
    </section>
  );
};

function Card({ image, title, id }) {
  async function deleteProject(id) {
    try {
      const response = await fetch(`/api/projects/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: sessionStorage.getItem("d"),
        },
      });
      if (!response.ok) {
        throw new Error("Failed to delete the project");
      }
      console.log("Project deleted successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  }
  const navigate = useNavigate();

  function editProject(id) {
    navigate("/edit/" + id);
  }

  function viewProject(id) {
    navigate("/project/" + id);
  }

  return (
    <div className="card">
      {sessionStorage.getItem("d") && (
        <>
          <button
            onClick={() => deleteProject(id)}
            style={{ backgroundColor: "red", color: "white", marginRight: 10 }}
          >
            Delete
          </button>
          <button
            onClick={() => editProject(id)}
            style={{ backgroundColor: "blue", color: "white" }}
          >
            Edit
          </button>
          <br />
        </>
      )}
      <img src={image} alt={title} />
      <h3 onClick={() => viewProject(id)}>{title}</h3>
    </div>
  );
}

function FloatingButton() {
  return <button className="floating-btn">+</button>;
}

const images = ["./slider1.jpeg", "./slider2.jpeg"];

function App() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  async function getProjects() {
    try {
      const response = await Axios.get("/api/projects"); // Step 2: Use Axios to get data
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  }

  return (
    <div>
      <Navbar />
      <ImageSlider images={images} />
      <div className="cards-container">
        {/* <Card image="./slider1.jpeg" title="Card Title Here" /> */}

        {projects.map((project) => (
          <Card
            image={"/api/image/" + project.thumbnail}
            title={project.title}
            id={project._id}
          />
        ))}
      </div>

      {/* <FloatingButton /> */}
    </div>
  );
}

export default App;
